import { IOrganization } from "@smartrr/shared/entities/Organization";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";

export type RedirectToShopifyURLType = "orders" | "customers";

export const redirectToShopify = (
  activeOrg: IOrganization | null,
  path: RedirectToShopifyURLType,
  id: string | undefined | null
) => {
  if (!activeOrg?.myShopifyDomain || !id) {
    return;
  }

  return frontEndTabOpen(`https://${activeOrg.myShopifyDomain}/admin/${path}/${shopifyGidToNumber(id)}`);
};
